export interface PagedResult<T> {
  totalPage: number
  pageSize: number
  pageNo: number
  total: number
  data: T[]
}

export enum UserRole {
  Admin = 'Admin',
  User = 'User',
}

export interface User {
  id: string,
  role: UserRole,
  username: string,
  name: string | null,
  password: string | null,
  phone: string | null,
  createAt: Date | null,
  updateAt: Date | null,
}

export interface CustomerModel {
  id: string,
  name: string | null,
  createAt: Date | null,
}

export interface KnowledgeBind {
  id: string,
  knowledgeBaseName: string | null,
  accountName: string | null,
  createAt: Date | null,
}

export interface CustomerMemory {
  id: string,
  content: string | null,
  createAt: Date | null,
}

export interface UpsertAccount {
  id: string,
  username: string,
  name: string | null,
  password: string | null,
  phone: string | null
}

export enum KnowledgeBaseType {
  Public = 'Public',
  Private = 'Private',
}

export interface KnowledgeBaseUpsert {
  id?: string;
  icon: string;
  name: string;
  type: KnowledgeBaseType;
}

export interface KnowledgeBase extends KnowledgeBaseUpsert {
  id: string;
  createAt: Date;
  updateAt: Date;
}

export enum KnowledgeKind {
  KnowledgeKindWordX = "docx",
  KnowledgeKindPdf = "pdf",
  KnowledgeKindImage = "image",
  KnowledgeKindMarkdown = "markdown",
  KnowledgeKindQA = "qa",
}

export interface KnowledgeData {
  id: string;
  knowledgeId: string;
  knowledgeBaseId: string;
  kind: string;
  title: string;
  description: string | null;
  vectored: boolean;
  error: string | null
  createAt: Date;
  updateAt: Date;
}

export interface DocumentKnowledgeData extends KnowledgeData {
  objectId: string;
  fileName: string;
}

export interface MarkdownKnowledgeData extends KnowledgeData {
  objectId: string;
  fileName: string;
  content: string;
}

export interface ImageKnowledgeData extends KnowledgeData {
  objectId: string;
  summary: string;
}

export interface QAKnowledgeData extends KnowledgeData {
  question: string;
  answer: string;
}

export interface Knowledge {
  error: string | null
  kind: string;
  data: DocumentKnowledgeData | MarkdownKnowledgeData | ImageKnowledgeData | QAKnowledgeData
}

export interface Bot {
  model: string;
  instructions: string;
  appId: string;
  appSecret: string;
  token: string;
  subscribeReply: string;
}
