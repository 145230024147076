import React, {useEffect} from "react";
import APIClient, {CustomerMemory, KnowledgeBind} from "../../api";
import dayjs from "dayjs";
import {useList} from "react-use";
import {Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";
import {useParams} from "react-router-dom";

export default function CustomerDetail() {

  const params = useParams();
  const customerId = params.customerId!;
  return (
    <div className="h-full flex *:flex-1 space-x-4">
      <div className="h-full">
        <KnowledgeBindList customerId={customerId}/>
      </div>
      <div className="h-full">
        <CustomerMemoryList customerId={customerId}/>
      </div>
    </div>

  )
}

function KnowledgeBindList({customerId}) {
  const columns = [
    {key: "knowledgeBaseName", name: "知识库名称"},
    {key: "accountName", name: "厂商名称"},
    {key: "createAt", name: "绑定时间"}
  ]
  const [knowledgeBaseBindList, setKnowledgeBaseBindList] = useList<KnowledgeBind>();

  useEffect(() => {
    (async () => {
      const list = await APIClient.customer.knowledgeBaseBindList(customerId);
      setKnowledgeBaseBindList.set(list)
    })()
  }, [setKnowledgeBaseBindList, customerId]);

  const renderKnowledgeCell = React.useCallback((knowledgeBind: KnowledgeBind, columnKey: React.Key) => {
    switch (columnKey) {
      case "knowledgeBaseName":
        return (
          <div>{knowledgeBind.knowledgeBaseName}</div>
        );
      case "accountName":
        return (
          <div>{knowledgeBind.accountName}</div>
        );
      case "createAt":
        return (<div>{dayjs(knowledgeBind.createAt).format("YYYY-MM-DD HH:mm")}</div>)
      default:
        return <div>...</div>;
    }
  }, []);


  return (
    <>
      <Table aria-label="知识库绑定列表">
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} align={column.key === "actions" ? "center" : "start"}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={knowledgeBaseBindList}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderKnowledgeCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}


function CustomerMemoryList({customerId}) {
  const columns = [
    {key: "customerMemory", name: "客户画像"},
    {key: "createAt", name: "创建时间"}
  ]
  const [customerMemoryList, setCustomerMemoryList] = useList<CustomerMemory>();
  const renderKnowledgeCell = React.useCallback((customerMemory: CustomerMemory, columnKey: React.Key) => {
    switch (columnKey) {
      case "customerMemory":
        return (
          <div>{customerMemory.content}</div>
        );
      case "createAt":
        return (<div>{dayjs(customerMemory.createAt).format("YYYY-MM-DD HH:mm")}</div>)
      default:
        return <div>...</div>;
    }
  }, []);

  useEffect(() => {
    (async () => {
      const list = await APIClient.customer.MemoryList(customerId);
      setCustomerMemoryList.set(list)
    })()
  }, [setCustomerMemoryList, customerId]);

  return (
    <>
      <Table aria-label="客户画像定列表">
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} align={column.key === "actions" ? "center" : "start"}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={customerMemoryList}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderKnowledgeCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}
